<template>
  <LiefengContent  :navList="navList">
      <template #title>{{titleName}}</template>
      <template #contentArea>
         <!--概况-->
          <Card style="margin:20px 0;">
              <TitleColor title='概况'>
                  <template #titleRight>
                      {{$core.formatDate(new Date(),'yyyy-MM-dd '+'00:00:00')}}
                  </template>
              </TitleColor>

               <!--街道使用情况-->
              <More @click="openStree" title="街道使用情况"></More>
              <div class="stree-div">
                  <div class="left">
                      <VeRing :ringData="streeUsage.ring" :listData='streeUsage.list'></VeRing>
                  </div>
                  <div class="right">
                      <VeTable :tableData="streeUsage.tableData" :tableColumn="streeUsage.streeColumn"></VeTable>
                  </div>
              </div>
              <!--用户分布情况-->
              <More @click="openUser" title="用户分布情况"></More>
              <div class="stree-div">
                  <div class="left">
                      <VeRing :ringData="userSpread" :listData='userList'></VeRing>
                  </div>
                  <div class="right">
                      <VeTable :tableData="userSpreadData" :tableColumn="userSpreadColumn"></VeTable>
                  </div>
              </div>

              <!--属地化情况-->
              <More @click="openCompany" title="属地化情况"></More>
              <div class="stree-div">
                  <div class="left">
                      <VeRing :ringData="company.ring" :listData='company.ring'></VeRing>
                  </div>
                  <div class="right">
                      <VeTable :tableData="company.tableData" :tableColumn="company.streeColumn"></VeTable>
                  </div>
              </div>
          </Card>
          <!--社区组织动员体系建设进度情况-->
          <Card style="margin:20px 0 ">
              <TitleColor title='社区组织动员体系建设进度情况' showBtn="true" btnTitle="更多数据详情" @click="openbuildprogress">
                  <template #titleRight>
                      {{$core.formatDate(new Date(),'yyyy-MM-dd '+'最新评估结果')}}
                  </template>
              </TitleColor>
              <div class="stree-div">
                  <div class="left">
                      <Ring :colors='colors' :data="schedule" ></Ring>
                  </div>
                  <div class="right">
                      <VeTable :tableData="scheduleData" :tableColumn="scheduleColumn"></VeTable>
                  </div>
              </div>
          </Card>
          <!--栏目建设情况-->
          <Card style="margin:20px 0 ">
              <TitleColor title='栏目建设情况' showBtn="true" btnTitle="更多数据详情" @click="openColumn">
                  <template #titleRight>
                      {{$core.formatDate(new Date(),'yyyy-MM-dd '+'00:00:00 更新')}}
                  </template>
              </TitleColor>
             <div class="card-view">
                 <CardItem style="width:400px" cardTitle="栏目开通数(个)" :list="columnList"></CardItem>
                 <CardItem style="width:300px" cardTitle="栏目数据发布情况(条)" :list="sendList" background="linear-gradient(to right,#40ACF7,#737CFF)"></CardItem>
             </div>
             <div class="bar-div">
                 <VeBar :showSelect="false" :data="columnUsage.barData"  :settings="columnUsage.settings"></VeBar>
             </div>
             <div class="bar-div">
                 <VeBar :showSelect="false" :data="columnUsageTwo.barData"  :settings="columnUsageTwo.settings"></VeBar>
             </div>
          </Card>
          
          <!--栏目使用情况-->
          <Card style="margin:20px 0 ">
              <TitleColor title='栏目使用情况（前5）' showBtn="true" btnTitle="更多数据详情" @click="openColumnUse">
                  <template #titleRight>
                      {{$core.formatDate(new Date(),'yyyy-MM-dd '+'00:00:00 更新')}}
                  </template>
              </TitleColor>
             <div class="table-view">
                 <VeTable :tableData="columnUseData" :tableColumn="columnUseColumn"></VeTable>
             </div>
             <div class="bar-div">
                 <VeBar :showSelect="false"  :data="histogramData" ></VeBar>
             </div>
          </Card>
           <!--重点任务-->
          <!-- <Card style="margin:20px 0 ">
              <TitleColor title='重点任务进度检查'>
              </TitleColor>    
              <More :showBtn="false" title="荔湾区当前下达任务：统计"></More>
              <div class="stree-div">
                  <div class="left">
                      <VeRing :ringData="sexData"></VeRing>
                  </div>
                  <div class="right">
                      <VeTable :tableData="streeData" :tableColumn="streeColumn"></VeTable>
                  </div>
              </div>
          </Card> -->
          <Card style="margin:20px 0 ">
              <TitleColor title='用户关注度分析'>
              </TitleColor>
              <div class="user-view">
                  <TableList class="list-div" :titleName="titleName + '最新热门资讯'" :tableList='infomationTwoList' ></TableList>
                  <TableList class="list-div" :titleName="titleName + '最新热门活动'" :tableList='infomationList'></TableList>
              </div>
              
          </Card>
      </template>
  </LiefengContent>
</template>

<script>
import LiefengContent from '../components/LiefengContent2'
import TitleColor from '../components/TitleColor'
import More from '../components/More'
import VeRing from '../components/VeRing'
import Ring from 'v-charts/lib/ring.common'
import VeTable from '../components/Table'
import CardItem from '../components/CardItem'
import VeHistogram from '../components/VeHistogram'
import VeBar from '../components/VeBar'
import Bar from 'v-charts/lib/bar.common'
import TableList from '../components/TableList'
import {mixinRequest} from '../utils/mixinRequest.js'
export default {
    components:{LiefengContent,TitleColor,More,VeRing,Ring,VeTable,CardItem,VeHistogram,Bar,VeBar,TableList},
    mixins:[mixinRequest],
    data(){
        return{
            navList:[],
            titleName:'门户页',
            portalInfo:{},

            // 街道使用情况数据
            streeUsage:{
                list:{},
                ring:{
                },
                tableData:[],
                streeColumn:[]
            },

            // 属地化情况
            company:{
                ring:{
                },
                tableData:[],
                streeColumn:[]
            },
            //用户分布情况
            userSpread: {
                columns: ["示范", "用户数量"],
                rows: [
                ],
            },
            userList:{
               rows: [
                ], 
            },
            userSpreadColumn: [
                {
                    title: "排名",
                    key: "no",
                    align: "center"
                },
                {
                    title: "街道名称",
                    key: "name",
                    align: "center",
                    render:(h,params) => {
                        return h('div',[
                        h('a',{
                            on:{
                            'click':()=>{
                                // 点击跳转
                                this.navList.push({name:this.portalInfo.orgName+'用户分布情况',router:'/userspread',orgCode:this.portalInfo.orgCode})
                                this.$router.push(`/userspread?router=${JSON.stringify(this.navList)}&orgName=${params.row.name}&orgCode=${params.row.orgCode}`)
                                }
                            }
                        },params.row.name)
                        ])
                    }

                },
                {
                    title: "用户数",
                    key: "num",
                    align: "center"
                },
            ],
            userSpreadData: [],

            columnList:[
                {title:'栏目数',num: 0},
                {title:'子栏目数',num: 0},
                {title:'空白栏目',num: 0}
            ],
            sendList:[
                 {title:'发布数量',num: 0},
                 {title:'公布中数量',num: 0},
            ],
            thirdList:[
                {title:'链接第三方资源数',num:'16'},
            ],
            barExtend:{
               series: {
                barMaxWidth: 30
                }
            },
            barSelect:{
                value:'',
                list:[
                    {label:'男',value:'1'},
                    {label:'女',value:'2'},
                    {label:'未知',value:'0'},
                ]
            },
            tableList:[
                {title:'社区返穗人员登记表(二)1社区返穗人员登记表(二)1社区返穗人员登记表(二)1社区返穗人员登记表(二)1社区返穗人员登记表(二)1社区返穗人员登记表(二)1社区返穗人员登记表(二)1社区返穗人员登记表(二)1社区返穗人员登记表(二)1社区返穗人员登记表(二)1',num:'点击1w次'},
                {title:'社区返穗人员登记表(二)2',num:'点击1w次'},
            ],
            columnUsage:{
                ring:{
                },
                tableData:[],
                streeColumn:[],
                barData:[],
                settings:{
                    stack:{'社区':['栏目数','子栏目数']}
                },
            },
            columnUsageTwo:{
                ring:{
                },
                tableData:[],
                streeColumn:[],
                barData:[],
                settings:{
                    stack:{'社区':['发布数量','公布中数量']}
                },
            },

            // 用户数据分析
            infomationList:[],
            infomationTwoList:[],

            // 社区组织社区建设进度情况
            scheduleColumn:[
                {title:'街道名称',key:'name',align: "center",
                    render:(h,params) => {
                        return h('div',[
                        h('a',{
                            on:{
                            'click':()=>{
                                // 点击跳转
                                this.navList.push({name:this.portalInfo.orgName+'组织动员体系建设进度情况',router:'/buildprogress',orgCode:this.portalInfo.orgCode})
                                this.$router.push(`/buildprogress?router=${JSON.stringify(this.navList)}&orgName=${params.row.name}&orgCode=${params.row.orgCode}`)
                                }
                            }
                        },params.row.name)
                        ])
                    }
                },
                {title:'未启动',key:'notStarted',align: "center",},
                {title:'仅导入架构',key:'importOnly',align: "center",},
                {title:'仅创建工作群',key:'buildGroup',align: "center",},
                {title:'使用中',key:'useing',align: "center",},
            ],
            scheduleData:[
            ],
            // 进度
            schedule:{
                columns:['进度','个数'],
                rows:[
                ]
            },
            colors:['#61e9f1','#56a3d5', '#4b83d9','#e6d677','#cfa47c','#2fb56e','#599e1e','#7841ff','#1312d2','#b14140','#b4428c'],


            // 栏目使用情况
            columnUseColumn:[
                {title:'栏目名称',align:"center",key:'name'},
                {title:'点击量（次）',align:"center",key:'one'},
                {title:'参与数（次）',align:"center",key:'three'}
            ],
            columnUseData:[
            ],

            histogramData:{
                columns: ["街道", "点击量（次）","参与数（次）",],
                rows: [
                ]
            },
            allUserExtend: {
                // barMinWidth: 15,
            },

            
        }
    },
    async created(){
        await this.getOrg()
        this.portalInfo = this.$store.state.portal
        this.titleName = this.portalInfo.orgName ? this.portalInfo.orgName + '门户页' :'门户页'
        this.navList.push({
            name:this.portalInfo.orgName ? this.portalInfo.orgName + '门户页' :'门户页',
            router:this.$route.path
        })

        await this.getStree()
        await this.getUser()
        await this.getCompany()
        await this.getBuild()
        await this.getColumn()
        await this.getColumnUse()
        await this.userNum()
        // this.histogramData.
        //         rows=[
        //             { 街道: "逢源街1", "点击量（次）":200,"点赞数（次）":300,"参与数（次）":400 },
        //             { 街道: "逢源街2", "点击量（次）":200,"点赞数（次）":300,"参与数（次）":400 },
        //             { 街道: "逢源街3", "点击量（次）":200,"点赞数（次）":300,"参与数（次）":400 },
        //             { 街道: "逢源街4", "点击量（次）":200,"点赞数（次）":300,"参与数（次）":400 },
        //             { 街道: "逢源街5", "点击量（次）":200,"点赞数（次）":300,"参与数（次）":400 },
        //             { 街道: "逢源街6", "点击量（次）":200,"点赞数（次）":300,"参与数（次）":400 },
        //             { 街道: "逢源街7", "点击量（次）":200,"点赞数（次）":300,"参与数（次）":400 },
        //             { 街道: "逢源街8", "点击量（次）":200,"点赞数（次）":300,"参与数（次）":400 },
        //             { 街道: "逢源街9", "点击量（次）":200,"点赞数（次）":300,"参与数（次）":400 },
        //             { 街道: "逢源街0", "点击量（次）":200,"点赞数（次）":300,"参与数（次）":400 },
        //             { 街道: "逢源街1", "点击量（次）":200,"点赞数（次）":300,"参与数（次）":400 },
        //             { 街道: "逢源街2", "点击量（次）":200,"点赞数（次）":300,"参与数（次）":400 },
        //             { 街道: "逢源街3", "点击量（次）":200,"点赞数（次）":300,"参与数（次）":400 },
        //             { 街道: "逢源街4", "点击量（次）":200,"点赞数（次）":300,"参与数（次）":400 },
        //             { 街道: "逢源街5", "点击量（次）":200,"点赞数（次）":300,"参与数（次）":400 },
        //             { 街道: "逢源街6", "点击量（次）":200,"点赞数（次）":300,"参与数（次）":400 },
        //             { 街道: "逢源街7", "点击量（次）":200,"点赞数（次）":300,"参与数（次）":400 },
        //             { 街道: "逢源街8", "点击量（次）":200,"点赞数（次）":300,"参与数（次）":400 },
        //             { 街道: "逢源街9", "点击量（次）":200,"点赞数（次）":300,"参与数（次）":400 },
        //             { 街道: "逢源街0", "点击量（次）":200,"点赞数（次）":300,"参与数（次）":400 },
        //             { 街道: "逢源街", "点击量（次）":200,"点赞数（次）":300,"参与数（次）":400 },
        //             { 街道: "逢源街", "点击量（次）":200,"点赞数（次）":300,"参与数（次）":400 },
        //             { 街道: "逢源街", "点击量（次）":200,"点赞数（次）":300,"参与数（次）":400 },
        //             { 街道: "逢源街", "点击量（次）":200,"点赞数（次）":300,"参与数（次）":400 },
        //             { 街道: "逢源街", "点击量（次）":200,"点赞数（次）":300,"参与数（次）":400 },
        //             { 街道: "逢源街", "点击量（次）":200,"点赞数（次）":300,"参与数（次）":400 },
        //         ]
            
       
       
    },
    methods:{
        // 方法部分
        // 栏目建设情况跳转按钮
        openColumn(){
            this.$router.push(`/columnBuild?router=${JSON.stringify(this.navList)}`)
        },
        // 街道使用情况更多数据按钮
        openStree(){
            this.$router.push(`/streetUsage?router=${JSON.stringify(this.navList)}`)
        },
        openUser(){
            this.$router.push(`/userspread?router=${JSON.stringify(this.navList)}`)
        },
        openCompany(){
            this.$router.push(`/companysituation?router=${JSON.stringify(this.navList)}`)
        },
        openbuildprogress(){
            this.$router.push(`/buildprogress?router=${JSON.stringify(this.navList)}`)
        },
        openColumnUse(){
            this.$router.push(`/columnuse?router=${JSON.stringify(this.navList)}`)
        },
        // 街道使用情况数据处理
       async getStree(){
             // 判断是否获取当了当前用户的信息
            if(this.portalInfo && this.portalInfo.orgLevel != ''){
                // 判断当前维度
                if(this.portalInfo.orgLevel == 3){
                    // 社区
                   let data = await this.getOrgStreeUsage(this.portalInfo.orgCode)
                   this.streeUsage.ring = {
                       columns:['title','num'],
                       rows:[
                           {title:'其他街道',num:data.other},
                           {title:'示范街道（覆盖街道数）',num:data.pilot},
                        //    {title:'街道总数',num:data.total},
                           {title:'试用街道（覆盖街道数）',num:data.trial},
                       ],
                   }
                   this.streeUsage.list = {
                       rows:[
                           {title:'街道总数',num:data.total},
                           {title:'示范街道（覆盖街道数）',num:data.pilot},
                           {title:'试用街道（覆盖街道数）',num:data.trial},
                           {title:'其他街道',num:data.other},
                       ],
                   }
                   this.streeUsage.streeColumn = [
                       {
                            title: '街道名称',
                            key: 'streetName',
                            align: "center",
                            render:(h,params) => {
                                return h('div',[
                                h('a',{
                                    on:{
                                    'click':()=>{
                                        // 点击跳转
                                        this.navList.push({name:this.portalInfo.orgName+'街道使用情况',router:'/streetUsage',orgCode:this.portalInfo.orgCode})
                                        this.$router.push(`/streetUsage?router=${JSON.stringify(this.navList)}&orgName=${params.row.streetName}&orgCode=${params.row.streetCode}`)
                                        }
                                    }
                                },params.row.streetName)
                                ])
                            }
                        },
                       {
                            title: '示范社区数',
                            key: 'pilotCommunity',
                            align: "center",
                        },
                       {
                            title: '试用社区数',
                            key: 'trialCommunity',
                            align: "center",
                        },
                       {
                            title: '社区总数',
                            key: 'toTalCommunity',
                            align: "center",
                        },
                    ]
                    if(data.streetRoList && data.streetRoList.length > 0 ) this.streeUsage.tableData = data.streetRoList
                    else this.streeUsage.tableData = []
                    

                //     ring:{
                // },
                // tableData:[],
                // streeColumn:[]


                   
                }else if(this.portalInfo.orgLevel == 4){
                    // 街道
                   let data = await this.getStreeList(this.portalInfo.orgCode)
                   this.streeUsage.ring = {
                       columns:['title','num'],
                       rows:[
                           {title:'其他社区',num:data.other},
                           {title:'示范社区（覆盖社区数）',num:data.pilot},
                        //    {title:'社区总数',num:data.total},
                           {title:'试用社区（覆盖社区数）',num:data.trial},
                       ],
                    
                   }
                   this.streeUsage.list = {
                       rows:[
                           {title:'社区总数',num:data.total},
                           {title:'示范社区（覆盖社区数）',num:data.pilot},
                           {title:'试用社区（覆盖社区数）',num:data.trial},
                           {title:'其他社区',num:data.other},
                       ],
                   }
                   this.streeUsage.streeColumn = [
                       {
                            title: '社区名称',
                            key: 'orgName',
                            align: "center",
                        },
                       {
                            title: '使用情况',
                            key: 'orgOnlineType',
                            align: "center",
                        },
                       {
                            title: '上线时间',
                            key: 'gmtCreate',
                            align: "center",
                            render:(h,params) =>{
                                return h('div',{},params.row.gmtCreate ? this.$core.formatDate(
                                        new Date(params.row.gmtCreate),
                                        "yyyy-MM-dd"
                                      ):'')
                            }
                        },
                   ]
                   if(data.communityRoList && data.communityRoList.length > 0)this.streeUsage.tableData = data.communityRoList
                   else this.streeUsage.tableData = []
                   
                }
            }
        },
        //用户分布
        async getUser(){
            if(this.portalInfo && this.portalInfo.orgLevel != ''){
                let userData = await this.getStatUserByOrgCode(this.portalInfo.orgCode)
                this.userSpread.rows = [
                    {示范:'示范社区用户数',用户数量:userData.pilotUser},
                    {示范:'试用社区用户数',用户数量:userData.trialUser},
                    {示范:'其他社区用户数',用户数量:userData.otherUser}
                ]
                this.userList.rows = [
                    {示范:'用户总数',用户数量:userData.userTotal},
                    {示范:'示范社区用户数',用户数量:userData.pilotUser},
                    {示范:'试用社区用户数',用户数量:userData.trialUser},
                    {示范:'其他社区用户数',用户数量:userData.otherUser}
                ]
                userData.streetVoList.map( item => {
                    this.userSpreadData.push(
                    {no:item.no,name:item.orgName,num:item.totalOrgUser,orgCode:item.orgCode}
                    )
                })
                   
            }
        },
        //属地化
        async getCompany(){
            if(this.portalInfo && this.portalInfo.orgLevel != ''){
                let companyData = await this.getCompanyByZone(this.portalInfo.orgCode)
                    this.company.ring = {
                        columns:['title','num'],
                        rows:[
                            {title:'属地登记数',num:companyData.regNum},
                            {title:'服务主体入驻数',num:companyData.serviceAmount}
                        ]
                    }
                    this.company.streeColumn = [
                        {title:'排名',key:'no',align: "center"},
                        {title:'街道名称',key:'name',align: "center"},
                        {title:'属地化登记数',key:'num',align: "center"},
                        {title:'服务主体入驻数',key:'serviceAmount',align: "center"},
                    ]
                    console.log(companyData)
                    if(companyData.dataByStreetList && companyData.dataByStreetList.length > 0 ) this.company.tableData = companyData.dataByStreetList
                    else this.company.tableData = [] 
            }

        },

        // 社区组织动员体系建设进度情况
        async getBuild(){
            if(this.portalInfo && this.portalInfo.orgLevel != ''){
                this.scheduleData = []
                this.schedule.rows = []
                let buildData = await this.getConstructionStatusTotal(this.portalInfo.orgCode)
                this.schedule.rows=[
                {进度:'使用中',个数:buildData.usingTotal},
                {进度:'仅建工作群',个数:buildData.buildGroupTotal},
                {进度:'仅导入架构',个数:buildData.importSchemaTotal},
                {进度:'未启动',个数:buildData.notInitiatedTotal},
                ]
                
                buildData.dataList.map( item => {
                this.scheduleData.push({orgCode:item.orgCode,name:item.orgName,notStarted:item.notInitiated,importOnly:item.importSchema,buildGroup:item.buildGroup,useing:item.using})
            })
            }
        },

        // 栏目建设情况
        async getColumn(){
            
             if(this.portalInfo && this.portalInfo.orgLevel != ''){
                // 判断当前维度

                if(this.portalInfo.orgLevel == 3){
                  let data = await this.selectOrgInfo(this.portalInfo.orgCode)
                    if(data){
                        this.columnList = [
                            {title:'栏目数',num:data.columnAmount || 0},
                            {title:'子栏目数',num:data.columnChild || 0},
                            {title:'空白栏目',num:data.columnBlank || 0}
                        ]
                        this.sendList=[
                            {title:'发布数量',num: data.published + data.unpublished || 0},
                            {title:'公布中数量',num:data.published || 0},
                        ]
                    }
                    let columnData = await this.selectOrgChildInfo(this.portalInfo.orgCode)
                    if(columnData && columnData.length){
                        this.columnUsage.barData = {
                                columns:['名称','栏目数','子栏目数'],
                                rows:[]
                            }
                        this.columnUsageTwo.barData = {
                            columns:['名称','发布数量','公布中数量'],
                            rows:[]
                        }
                        columnData.map(item =>{
                            this.columnUsage.barData.rows.push({
                                '名称':item.orgName,
                                '栏目数':item.columnAmount,
                                '子栏目数': item.columnChild
                            })
                            this.columnUsageTwo.barData.rows.push({
                                '名称':item.orgName,
                                '发布数量':(item.published || 0) + (item.unpublished || 0),
                                '公布中数量':item.published
                            })
                        })
                        
                    }
                }else if(this.portalInfo.orgLevel == 4){
                    // 街道
                  let data = await this.selectOrgInfo(this.portalInfo.orgCode)
                    if(data){
                        this.columnList = [
                            {title:'栏目数',num:data.columnAmount || 0},
                            {title:'子栏目数',num:data.columnChild || 0},
                            {title:'空白栏目',num:data.columnBlank || 0}
                        ]
                        this.sendList=[
                            {title:'发布数量',num: data.published + data.unpublished || 0},
                            {title:'公布中数量',num:data.published || 0},
                        ]
                    }
                    let columnData = await this.selectOrgChildInfo(this.portalInfo.orgCode)
                    if(columnData && columnData.length){
                       this.columnUsage.barData = {
                                columns:['名称','栏目数','子栏目数'],
                                rows:[]
                            }
                        this.columnUsageTwo.barData = {
                            columns:['名称','发布数量','公布中数量'],
                             rows:[]
                        }
                        columnData.map(item =>{
                            this.columnUsage.barData.rows.push({
                                '名称':item.orgName,
                                '栏目数':item.columnAmount,
                                '子栏目数': item.columnChild
                            })
                            this.columnUsageTwo.barData.rows.push({
                                '名称':item.orgName,
                                '发布数量':(item.published || 0) + (item.unpublished || 0),
                                '公布中数量':item.published
                            })
                        })
                    }
                }               
            }
        },
        // 栏目使用情况
        async getColumnUse(){
            if(this.portalInfo && this.portalInfo.orgLevel != ''){
                // 图标
                let ColumnUse = await this.selectColumnUseDataInfo(this.portalInfo.orgCode)
                ColumnUse = ColumnUse.sort(this.sorts_fun('browseUv'))
                ColumnUse.every( (e,i) => {
                    this.columnUseData.push(
                        {name:e.columnNameFull,one:e.browseUv || 0,three:e.participateUv || 0,},
                    )
                    // 前五
                    return i <4
                    
                })
                // 条形
                let streeUse = await this.selectColumnUseChildData(this.portalInfo.orgCode)
                streeUse.map( e => {
                        this.histogramData.rows.push(
                            { '街道': e.orgName, "点击量（次）":e.browseUv || 0,"参与数（次）":e.participateUv || 0 },
                        )
                })
            }
        },
        // 用户数据分析
        async userNum(){
            let parmas = {
                statType:'1',
                orgCode:this.portalInfo.orgCode
            }
            let parmasTwo = {
                statType:'2',
                orgCode:this.portalInfo.orgCode
            }
            let data = await this.selectInformationBehavior(parmas)
            if(data && data.length){
                data.map(item =>{
                    this.infomationList.push({
                        title:item.title,
                        num:`参与${item.statValue}次`
                    })
                })
            }else{
                this.infomationList = []
            }
            let dataTwo = await this.selectInformationBehavior(parmasTwo)
            if(dataTwo && dataTwo.length){
                dataTwo.map(item =>{
                    this.infomationTwoList.push({
                        title:item.title,
                        num:`点击${item.statValue}人`
                    })
                })
            }else{
                this.infomationTwoList = []
            }
        }
    }
}
</script>


<style lang="less" scoped>
.stree-div{
    display: flex;
    margin-top: 20px;
    .left{
        width: 50%;
    }
    .right{
        margin-left: 20px;
        width: 50%;
        min-height: 200px;
        max-height: 500px;
    }
}
.card-view{
    display: flex;
    height: 120px;
    justify-content: center;
}
.bar-div{
    // min-height: 400px;
    width: 100%;
    margin-top: 20px;
    border-bottom: 1px dashed #ccc;
    &:nth-last-child(1){
        border-bottom: none;   
    }
}
.table-view{
    width: 100%;
    min-height: 200px;
    max-height: 500px;
    border-bottom: 1px dashed #ccc;
    padding-bottom: 20px;
}
.user-view{
    width: 100%;
    display: flex;
    justify-content: space-between;
    height: 500px;
    .list-div{
        height: 100%;
        width: 45%;
    }
}
</style>