<template>
  <div class="card" :style="`background-image:${background}`">
      <div class="top">
          {{cardTitle}}
      </div>
      <div class="bottom" v-if="list && list.length > 0">
          <div class="item" v-for="(item,index) in list" :key="index">
              <div class="num">{{item.num}}</div>
              <div class="title">{{item.title}}</div>
          </div>
      </div>
  </div>
</template>

<script>
export default {
    props:{
        background:{
            type:String,
            default:() => {
                return 'linear-gradient(to right,#5770FB, #C577FF)'
            }
        },
        list:{
            type:Array,
            default:() =>{
                return []
            }
        },
        cardTitle:{
            type:String,
            default:() =>{
                return ''
            }
        }
    }
}
</script>

<style lang="less" scoped>
.card{
    box-shadow: 0px 0px 10px 2px #888;
    width: 100%;
    height: 100%;
    text-align: center;
    margin-right: 20px;
    border-radius: 6px;
    cursor: pointer;
    color: #ffffff;
    &:hover{
        box-shadow: 0px 0px 10px 2px #666;
    }
    .top{
        padding:20px 0 10px 0;
        font-size: 16px;
    }
    .bottom{
        display: flex;
        .item{
            flex: 1;
            margin: 0 10px;
            .num{
                font-size: 24px;
                padding-bottom: 5px;
            }
            .title{
                opacity: 0.5;
            }
        }
    }
}
</style>